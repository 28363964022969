import React, { useEffect } from "react";
import "./AlertNotification.css";

const AlertIcons = {
  good: "/alert/good.svg",
  delete: "/alert/Delete.svg",
  danger: "/alert/Danger.svg"
}

const AlertNotification = ({
  message,
  onClose,
  duration = 3000,
  isVisible,
  removed = false,
  icon
}) => {
  useEffect(() => {
    if (isVisible) {
      const timer = setTimeout(() => {
        onClose();
      }, duration);

      return () => clearTimeout(timer);
    }
  }, [isVisible, duration, onClose]);

  const iconSrc = removed ? AlertIcons.delete : AlertIcons[icon] || AlertIcons.good;


  return (
    <div className={`alert-notification ${isVisible ? "show" : ""}`}>
      <img src={iconSrc} alt="about" />
      <span>{message}</span>
    </div>
  );
};

export default AlertNotification;
