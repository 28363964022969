import React from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { getDescription, getTitle } from "../helpers/textHelper";

const NotificationItem = ({ item, handleNotificationModelShow }) => {
  const { t, i18n } = useTranslation();

  return (
    <div
      className="w-100 Accordion-section"
      style={{
        backgroundColor: item.isRead ? "#ffffff" : "#F2F3F7",
      }}
      onClick={() => handleNotificationModelShow(item._id)}
    >
      <div className="Accordion-main-containt">
        <div className="d-flex">
          <img src="/email notification.svg" alt="email" />
          <div>
            <div className="notification-subject">{getTitle(item, t)}</div>
          </div>
        </div>
        <div className="d-flex align-items-center">
          <div className="notification-date-time">
            {moment.unix(item.timestamp).format("DD/MM/YYYY")}{" "}
            {moment.unix(item.timestamp).format("HH:mm")}
          </div>
        </div>
      </div>
      <div className="Accordion-body-hide-description">
        {getDescription(item, t)}
      </div>
    </div>
  );
};

export default NotificationItem;
