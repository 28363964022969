import React from "react";
import { useTranslation } from "react-i18next";

import "./style.css";

const ChatLoader = () => {
  const { t, i18n } = useTranslation();

  return (
    <div className="chat__loader-wrapper">
      {Array(8)
        .fill(0)
        .map((item, index) => {
          return (
            <div className="msg-loader-wrapper" key={index}>
              <div className="msg-loader__user-avatar skeleton-placeholder-light"></div>
              <div className="msg-loader__text-wrapper">
                <div className="msg-loader__username skeleton-placeholder-light"></div>
                <div className="msg-loader__text skeleton-placeholder-light"></div>
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default ChatLoader;
