import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";

import { format, isToday } from "date-fns";
import { uk, enUS } from "date-fns/locale";

import ToolTip from "../../ToolTip/ToolTip";
import Model from "../../../molecule/model/Model";

import {
  prepareFullName,
  prepareUserProfileLink,
} from "../../../../helpers/formater";

const ChatMessage = ({ msg, user }) => {
  const { t, i18n } = useTranslation();
  const isMobile = useMediaQuery({ maxWidth: 768 });

  const lang = localStorage.getItem("language") === "en" ? enUS : uk;

  const textRef = useRef(null);

  const [showFullText, setShowFullText] = useState(false);
  const [isOverLines, setIsOverLines] = useState(false);
  const [activeUrl, setActiveUrl] = useState(null);
  const [showUrlModal, setShowUrlModal] = useState(false);

  const urlRegex = /(https?:\/\/[^\s]+)/g;

  useEffect(() => {
    if (textRef.current) {
      const lineHeight = parseInt(
        window.getComputedStyle(textRef.current).lineHeight
      );

      const lines = isMobile ? 5 : 3;
      const maxHeight = lineHeight * lines;

      if (textRef.current.scrollHeight > maxHeight) {
        setIsOverLines(true);
      }
    }
  }, []);

  const toggleFullText = () => {
    setShowFullText(!showFullText);
  };

  const formatEditDate = () => {
    return isToday(new Date(msg.editDate))
      ? lang === enUS
        ? `Today at ${format(new Date(msg.editDate), "HH:mm", {
            locale: lang,
          })}`
        : `Сьогодні о ${format(new Date(msg.editDate), "HH:mm", {
            locale: lang,
          })}`
      : lang === enUS
      ? `${format(new Date(msg.editDate), "d MMMM", {
          locale: lang,
        })} at ${format(new Date(msg.editDate), "HH:mm", { locale: lang })}`
      : `${format(new Date(msg.editDate), "d MMMM", {
          locale: lang,
        })} о ${format(new Date(msg.editDate), "HH:mm", { locale: lang })}`;
  };

  const handleUrlOpen = () => {
    setActiveUrl(null);
    setShowUrlModal(false);
    window.open(activeUrl, "_blank");
  };

  const handleUrlClick = (e, url) => {
    e.preventDefault();
    setShowUrlModal(true);
    setActiveUrl(url);
  };

  const prepareText = () => {
    const text = msg?.message.trim();
    const parts = text.split(urlRegex).map((part, index) =>
      urlRegex.test(part) ? (
        <a
          href={part}
          key={index}
          className="message__msg-url"
          onClick={(e) => handleUrlClick(e, part)}
        >
          {part}
        </a>
      ) : (
        part
      )
    );

    return <>{parts}</>;
  };

  return (
    <>
      <div className="message__msg-text">
        <div
          ref={textRef}
          className={`message__msg-main_txt ${
            isOverLines && "read-more-less_text"
          }  ${showFullText && "read-more-less_full-text"}`}
        >
          {msg.replayTo && (
            <a
              href={prepareUserProfileLink(msg.replayTo, user)}
              className="chat__replayTo-user"
            >
              {prepareFullName(msg.replayTo)}
            </a>
          )}
          {prepareText()}{" "}
          {msg.isEdited && (
            <ToolTip text={formatEditDate()}>
              <span className="message__msg-edited">({t("chat.edited")})</span>
            </ToolTip>
          )}
        </div>
        {!showFullText && isOverLines && (
          <button className="read-more-less_btn" onClick={toggleFullText}>
            {t("readMore")}
          </button>
        )}
      </div>

      <Model
        isModel={showUrlModal}
        modelClassName={"open-msg-url__modal"}
        hideModel={() => setShowUrlModal(false)}
        modelTitle={t("chat.openUrlTitle")}
      >
        <div className="chat-modal__wrapper">
          <div className="chat-modal__warning-text">
            {t("chat.openUrlWarning", { url: activeUrl })}
          </div>
          <div className="chat-modal__controls">
            <div className="cancel-btn" onClick={() => setShowUrlModal(false)}>
              {t("chat.cancel")}
            </div>
            <div className="primary-btn" onClick={() => handleUrlOpen()}>
              {t("chat.openSite")}
            </div>
          </div>
        </div>
      </Model>
    </>
  );
};

export default ChatMessage;
