import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import "./navbarNotification.css";
import moment from "moment";
import "moment/min/locales";
import NotificationModal from "./NotificationModel";
import apiService from "../../../services/apiService";
import { getDescription, getTitle } from "./helpers/textHelper";

setTimeout(() => {});
const NavbarNotification = (props) => {
  const { t, i18n } = useTranslation();
  const [notificationList, setNotificationList] = useState([]);
  const [notificationId, setNotificationId] = useState("");
  const [modelShow, setModelShow] = useState(false);
  const navigation = useNavigate();
  useEffect(() => {
    setNotificationList(props.showNotification);
  }, [props]);
  const handleNotificationComponent = () => {
    navigation("/notification");
  };
  const handleClearAll = async () => {
    const res = await apiService.clearAllNotification();
    if (res) {
      props.increamentUserCount();
    }
  };
  const handleNotificationModelShow = async (id) => {
    setNotificationId(id);
    setTimeout(() => {
      props.increamentUserCount();
    }, 1000);
    setModelShow(true);
  };
  const handleNoticationModelHide = () => {
    setModelShow(false);
  };
  const handleNotificationTime = (createdAt) => {
    const pastTime = moment(createdAt);
    pastTime.locale(localStorage.getItem("language") == "ua" ? "uk" : "en");
    const timeAgoWithoutSuffix = pastTime.fromNow(false);
    return timeAgoWithoutSuffix;
  };
  return (
    <>
      <div className="notification-suggestion">
        <div className="pending-notifications-mainDiv">
          <div className="pending-notifications">
            <div className="d-flex align-items-center">
              <span className="notifications-count">
                {notificationList.length}
              </span>
              <span className="pending-notifications-title">
                {t("navbar.notification.pendingNotifications")}
              </span>
              <div className="clear-all" onClick={handleClearAll}>
                {t("navbar.notification.Clearall")}
              </div>
            </div>
            <div
              className="viewAll-div d-flex align-items-baseline"
              onClick={handleNotificationComponent}
            >
              <span className="viewAll pe-2">
                {t("navbar.notification.ViewAll")}{" "}
              </span>
              <span className="">
                <svg
                  width="13"
                  height="14"
                  viewBox="0 0 13 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    className="notificationArrow-path"
                    d="M11 6.99988L2.25 6.99988"
                    stroke="#000000"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    className="notificationArrow-path"
                    d="M7.4707 3.4857L10.9999 6.9997L7.4707 10.5143"
                    stroke="#000000"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </span>
            </div>
          </div>
          {/* No new notifications */}
          {notificationList.length <= 0 ? (
            <div className="No-new-notifications-div">
              <img
                src="/notificationIcon.svg"
                className="pb-2"
                alt="notification"
              />
              <div className="title">
                {t("navbar.notification.Nonewnotifications")}{" "}
              </div>
            </div>
          ) : (
            <div className="new-notificationsData-div">
              <div className="new-notificationsData-scroll">
                {notificationList?.map((item, index) => (
                  <div className="" key={index}>
                    <div
                      className="notification-detail"
                      onClick={() => handleNotificationModelShow(item._id)}
                    >
                      <img
                        src="/email notification.svg"
                        style={{ paddingTop: "2px" }}
                        alt="notification"
                      />
                      <div className="description">
                        <div className="notification-topic-title">
                          {getTitle(item, t)}
                        </div>
                        <div className="notification-topic-detail py-1">
                          {getDescription(item, t)}
                        </div>
                        <div className="notification-topic-time">
                          {handleNotificationTime(item.createdAt)}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
          {/* notification-Model */}
          <NotificationModal
            id={notificationId}
            isModel={modelShow}
            show={handleNotificationModelShow}
            hide={handleNoticationModelHide}
          />
        </div>
      </div>
    </>
  );
};
export default NavbarNotification;
