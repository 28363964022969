import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import Model from "../../molecule/model/Model";
import apiService from "../../../services/apiService";

import "./PredictionDetailsModalNotification.css";

const PredictionDetailsModalNotification = ({ showModal, setShowModal }) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const handleModalControlsClick = async (isOpen) => {
    const res = await apiService.updateUserNewPageStatus({
      pageDetailModalShowed: true,
    });
    if (res) {
      localStorage.setItem(
        "profile",
        JSON.stringify({
          ...JSON.parse(localStorage.getItem("profile")),
          pageDetailModalShowed: true,
        })
      );
    }
    setShowModal(false);

    if (isOpen) {
      const language = localStorage.getItem("language");
      const basePath = language === "en" ? "/en/predictions" : "/predictions";
      const predictionId = "667145d00d8c0bfbf1d86f13";
      
      navigate(`${basePath}/${predictionId}`);
    }
  };

  return (
    <div>
      <Model
        isModel={showModal}
        modelClassName={"prediction-details-modal"}
        hideModel={() => setShowModal(false)}
      >
        <div className="prediction-details-modal-content">
          <div className="prediction-details-video-wrapper">
            <iframe
              src="https://app.supademo.com/embed/cm59f1a813cshvlry6b8xecju?embed_v=2"
              loading="lazy"
              title="Capperauditor Demo"
              allow="clipboard-write"
              frameborder="0"
              webkitallowfullscreen="true"
              mozallowfullscreen="true"
              allowfullscreen
            ></iframe>
          </div>
          <div className="prediction-details-text__wrapper">
            <div className="prediction-details-text__title">
              {t("predictionDetailsModal.title")}
            </div>
            <div className="prediction-details-text__description">
              {t("predictionDetailsModal.description")}
              <ul>
                <li>{t("predictionDetailsModal.li1")}</li>
                <li>{t("predictionDetailsModal.li2")}</li>
                <li>{t("predictionDetailsModal.li3")}</li>
                <li>{t("predictionDetailsModal.li4")}</li>
                <li>{t("predictionDetailsModal.li5")}</li>
                <li>{t("predictionDetailsModal.li6")}</li>
                <li>{t("predictionDetailsModal.li7")}</li>
              </ul>
            </div>
            <div className="prediction-details-text__bottom-description">
              {t("predictionDetailsModal.bottomDescription")}
            </div>
          </div>
          <div className="page-detail-modal-control">
            <button
              className="modal-close-btn cancel-btn"
              onClick={() => handleModalControlsClick(false)}
            >
              {t("rewards.close")}
            </button>
            <button
              className="modal-open-btn primary-btn"
              onClick={() => handleModalControlsClick(true)}
            >
              {t("predictionDetailsModal.openBtn")}
            </button>
          </div>
        </div>
      </Model>
    </div>
  );
};

export default PredictionDetailsModalNotification;
