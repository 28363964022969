import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { uk, enUS } from "date-fns/locale";

import io from "socket.io-client";

import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";

import ChatMessage from "./components/ChatMessage";
import LoginModal from "../LoginModal/LoginModal";
import UserAvatar from "../../UserAvatar";
import Model from "../../molecule/model/Model";
import AlertNotification from "../AlertNotification/AlertNotification";
import ChatLoader from "../../CustomSkeletonLoaders/ChatLoader";
import ToolTip from "../ToolTip/ToolTip";
import MsgTimeDistance from "./components/MsgTimeDistance";

import {
  prepareFullName,
  prepareUserProfileLink,
} from "../../../helpers/formater";

import "./Chat.css";

const socket = io(process.env.REACT_APP_Url, {
  extraHeaders: {
    ...(localStorage.getItem("token")
      ? { Authorization: "Bearer " + localStorage.getItem("token") }
      : {}),
  },
});

const PAGE_SIZE = 10;
const MESSAGE_LIMIT = 500;

const Chat = ({
  gameId,
  user,
  setIsChatFullScreent,
  isChatFullScreent = true,
}) => {
  const { t, i18n } = useTranslation();
  const lang = localStorage.getItem("language") === "en" ? enUS : uk;
  const langStr = localStorage.getItem("language") === "en" ? "en" : "uk";

  const messagesContainerRef = useRef(null);
  const emojiRef = useRef(null);
  const controlsBtnsRef = useRef(null);
  const emojiPickerRef = useRef(null);
  const textareaRef = useRef(null);
  const emojiButtonRef = useRef(null);
  const sendButtonRef = useRef(null);
  const chatInputFormRef = useRef(null);

  const [page, setPage] = useState(1);
  const [isActionTriggered, setIsActionTriggered] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingMoreMessages, setIsLoadingMoreMessages] = useState(false);
  const [isMoreMessages, setIsMoreMessages] = useState(true);
  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState([]);
  const [newMessagesCount, setNewMessagesCount] = useState(0);
  const [isNewMessages, setIsNewMessages] = useState(false);
  const [isMessagesContainerScrolled, setIsMessagesContainerScrolled] =
    useState(false);

  const [isTextareaActive, setIsTextareaActive] = useState(false);
  const [isEmojiOpen, setIsEmojiOpen] = useState(false);

  const [isMessageLimit, setIsMessageLimit] = useState(false);
  const [isSentActive, setIsSentActive] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [editMsg, setEditMsg] = useState(null);
  const [removeMsgId, setRemoveMsgId] = useState(null);
  const [parentMessage, setParentMessage] = useState(null);

  const [showRemoveMsgModal, setShowRemoveMsgModal] = useState(false);
  const [showLoginModel, setShowLoginModel] = useState(false);
  const [showMsgRemovedNotification, setShowMsgRemovedNotification] =
    useState(false);
  const [showEmptyMsgNotification, setShowEmptyMsgNotification] =
    useState(false);

  useEffect(() => {
    if (emojiRef.current) {
      const shadowRoot =
        emojiRef.current.querySelector("em-emoji-picker").shadowRoot;

      if (shadowRoot) {
        const styleTag = document.createElement("style");
        styleTag.textContent = `
          #nav button[aria-selected="true"] {
            color: #000 !important; 
          }
          #nav button {
            color: #7F87B3 !important; 
          }
          .bar {
            background-color: #208B8E !important;
            height: 2px !important;
          }
          .search input[type="search"]:focus{ 
            border: 1px solid #208B8E !important;
            box-shadow: 0px 0px 0px 4px rgba(32, 139, 142, 0.2);
          }
          .sticky {
            font-weight: 600 !important;
          }
          .search {
            color: #7F87B3 !important; 
          }  
        `;
        shadowRoot.appendChild(styleTag);
      }
    }
  }, [isEmojiOpen]);

  useEffect(() => {
    socket.emit("joinGame", { gameId, pageSize: PAGE_SIZE });

    socket.on("editMessage", (updatedMessage) => {
      setMessages((prevMessages) =>
        prevMessages.map((msg) =>
          msg._id === updatedMessage._id ? updatedMessage : msg
        )
      );
    });

    socket.on("deleteMessage", (deletedMessageId) => {
      setMessages((prevMessages) =>
        prevMessages.filter((msg) => msg._id !== deletedMessageId)
      );

      setShowMsgRemovedNotification(true);
    });

    return () => {
      socket.off("editMessage");
      socket.off("deleteMessage");
    };
  }, [gameId]);

  useEffect(() => {
    const previousScrollHeight = messagesContainerRef.current?.scrollHeight;

    const handleLoadMessages = (loadedMessages) => {
      setIsLoading(false);

      if (loadedMessages.length < PAGE_SIZE) {
        setIsMoreMessages(false);
      }

      setIsLoadingMoreMessages(false);

      setMessages((prevMessages) => [
        ...loadedMessages.sort(
          (a, b) => new Date(a.timestamp) - new Date(b.timestamp)
        ),
        ...prevMessages,
      ]);

      setTimeout(() => {
        if (messagesContainerRef.current && previousScrollHeight) {
          const newScrollHeight = messagesContainerRef.current.scrollHeight;

          messagesContainerRef.current.scrollTop =
            newScrollHeight - previousScrollHeight;
        }
      }, 0);
    };

    socket.on("loadMessages", handleLoadMessages);

    return () => {
      socket.off("loadMessages", handleLoadMessages);
    };
  }, [page]);

  useEffect(() => {
    socket.on("message", (message) => {
      setMessages((prevMessages) => [...prevMessages, message]);

      if (isMessagesContainerScrolled && message.userId._id !== user?._id) {
        setNewMessagesCount((prevCount) => prevCount + 1);
        setIsNewMessages(true);
      }

      if (message.userId._id === user?._id) {
        setTimeout(() => scrollToBottom(), 0);
      }
    });

    return () => {
      socket.off("message");
    };
  }, [isMessagesContainerScrolled]);

  useEffect(() => {
    const messageLength = message.length;

    const isMessageExceedLimit = messageLength >= MESSAGE_LIMIT;
    const isMessageNonEmpty = messageLength > 0;

    setIsSentActive(isMessageNonEmpty);
    setIsMessageLimit(isMessageExceedLimit);
  }, [message]);

  const handleClickOutside = useCallback(
    (event) => {
      if (isActionTriggered) return;

      const clickedOutsideTextarea =
        textareaRef.current && !textareaRef.current.contains(event.target);
      const clickedOutsideEmojiButton =
        emojiButtonRef.current &&
        !emojiButtonRef.current.contains(event.target);
      const clickedOutsideSendButton =
        sendButtonRef.current && !sendButtonRef.current.contains(event.target);
      const clickedOutsideEmojiPicker =
        emojiRef.current && !emojiRef.current.contains(event.target);

      const clickedOutsideAllExceptControls =
        clickedOutsideTextarea &&
        clickedOutsideEmojiButton &&
        clickedOutsideSendButton &&
        (clickedOutsideEmojiPicker || clickedOutsideEmojiPicker === null);

      if (
        clickedOutsideAllExceptControls &&
        message.length === 0 &&
        !parentMessage
      ) {
        setIsTextareaActive(false);
        setIsEdit(false);
        resetFormInputSize();
      }
    },
    [
      isActionTriggered,
      textareaRef,
      emojiButtonRef,
      sendButtonRef,
      emojiRef,
      message,
      parentMessage,
    ]
  );

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [handleClickOutside]);

  const resetFormInputSize = () => {
    textareaRef.current.style.height = "40px";
    chatInputFormRef.current.style.height = "80px";
  };

  const scrollToBottom = () => {
    if (messagesContainerRef.current && !isLoading) {
      messagesContainerRef.current.scrollTop =
        messagesContainerRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [isLoading]);

  const sendMessage = () => {
    if (!user) {
      setShowLoginModel(true);
      return;
    }

    if (message.trim()) {
      if (isEdit) {
        if (editMsg.message !== message) {
          socket.emit("editMessage", {
            gameId,
            newContent: message,
            messageId: editMsg._id,
          });
        }
      } else {
        let data = {
          gameId,
          message,
        };

        if (parentMessage) {
          data.parentId = parentMessage._id;
          data.replayTo = parentMessage.userId._id;
        }

        socket.emit("sendMessage", data);
      }

      setIsEdit(false);
      // setIsTextareaActive(false);
      // setIsEmojiOpen(false);
      setParentMessage(null);
      setEditMsg(null);
      setMessage("");
      // resetFormInputSize();
    } else {
      setShowEmptyMsgNotification(true);
    }
  };

  const onEmojiClick = (emojiObject) => {
    setMessage((prevInput) => prevInput + emojiObject.native);
    setIsEmojiOpen(false);
    textareaRef.current.focus();
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && e.shiftKey) {
      e.preventDefault();
      const textarea = e.target;
      const start = textarea.selectionStart;
      const end = textarea.selectionEnd;

      setMessage((prev) => prev.slice(0, start) + "\n" + prev.slice(end));
      setTimeout(() => {
        textarea.setSelectionRange(start + 1, start + 1);
      }, 0);
    } else if (e.key === "Enter") {
      e.preventDefault();
      sendMessage();
    }
  };

  const handleFocus = () => {
    setIsTextareaActive(true);
    if (!isTextareaActive) {
      const textarea = textareaRef.current;
      textarea.style.height = "100px";

      const chatInputForm = chatInputFormRef.current;
      chatInputForm.style.height = "216px";
    }
  };

  const handleDeleteMsgClick = (msg) => {
    setShowRemoveMsgModal(true);
    setRemoveMsgId(msg._id);
  };

  const handleDeleteMsg = () => {
    socket.emit("deleteMessage", { messageId: removeMsgId, gameId });
    setShowRemoveMsgModal(false);
  };

  const handleEditMsg = (msg) => {
    setMessage(msg.message);
    setEditMsg(msg);
    setIsEdit(true);
    setParentMessage(null);
    setIsTextareaActive(true);
    textareaRef.current.focus();
  };

  const handleReplayMsg = (msg) => {
    setMessage("");
    setIsEdit(false);
    setIsTextareaActive(true);
    setParentMessage(msg);
    textareaRef.current.focus();
  };

  const handleCancelEdit = () => {
    setMessage("");
    setIsEdit(false);
    setIsTextareaActive(false);
    resetFormInputSize();
  };

  const handleCancelReplay = () => {
    if (message.length === 0) {
      setIsTextareaActive(false);
      resetFormInputSize();
    }
    setParentMessage(null);
  };

  const handleEmojiClick = (e) => {
    e.stopPropagation();
    setIsTextareaActive(true);

    setIsEmojiOpen((prevState) => !prevState);
  };

  const handleNewMessagesNotificationClick = () => {
    setIsNewMessages(false);
    setNewMessagesCount(0);

    messagesContainerRef.current.scrollTop =
      messagesContainerRef.current.scrollHeight;
  };

  const handleMessagesContainerScroll = (e) => {
    const element = e.target;
    const scrolledDistance = element.scrollTop;
    const maxScrollTop = element.scrollHeight - element.clientHeight;

    if (
      scrolledDistance < 5 &&
      !isLoadingMoreMessages &&
      isMoreMessages &&
      isChatFullScreent
    ) {
      setIsLoadingMoreMessages(true);

      const nextPage = page + 1;
      setPage(nextPage);
      socket.emit("fetchMessages", {
        gameId,
        page: nextPage,
        pageSize: PAGE_SIZE,
      });
    }

    if (scrolledDistance < maxScrollTop - 10) {
      setIsMessagesContainerScrolled(true);
    } else {
      setIsMessagesContainerScrolled(false);
      setIsNewMessages(false);
      setNewMessagesCount(0);
    }
  };

  const handleInput = () => {
    const textarea = textareaRef.current;
    textarea.style.height = "auto";
    textarea.style.height = `${textarea.scrollHeight}px`;

    const chatInputForm = chatInputFormRef.current;
    chatInputForm.style.height = "auto";
    chatInputForm.style.height = `${chatInputForm.scrollHeight}px`;
  };

  return (
    <div className="chat__wrapper">
      <div className="chat__messages-wrapper">
        {isLoading ? (
          <ChatLoader />
        ) : (
          <>
            {messages.length === 0 ? (
              <div className="chat__messages-no_message">
                <div className="chat__messages-no_message-box">
                  <div className="chat__messages-no_message-title">
                    {t("chat.noMsgTitle")}
                  </div>
                  <div className="chat__messages-no_message-description">
                    {t("chat.noMsgDescription")}
                  </div>
                </div>
              </div>
            ) : (
              <div
                className="chat__messages-section"
                ref={messagesContainerRef}
                onScroll={(e) => handleMessagesContainerScroll(e)}
              >
                {isMoreMessages && isChatFullScreent && (
                  <div
                    className={
                      isLoadingMoreMessages
                        ? "loading-more-msg__loader-wrapper"
                        : "loading-more-msg__loader-wrapper hidden"
                    }
                  >
                    <div className="circle-loader"></div>
                    {t("chat.loading")}
                  </div>
                )}
                {messages
                  .slice(isChatFullScreent ? 0 : -3)
                  .map((msg, index) => (
                    <div className="chat__message-wrapper" key={msg._id}>
                      <UserAvatar user={msg.userId} />
                      <div className="message__msg-content">
                        <div className="message__msg-title">
                          <a
                            href={prepareUserProfileLink(msg.userId, user)}
                            className="message__msg-username"
                          >
                            {prepareFullName(msg.userId)}
                          </a>
                          <span className="chat__separator">•</span>
                          <MsgTimeDistance
                            timestamp={msg.timestamp}
                            lang={lang}
                          />
                        </div>
                        <ChatMessage msg={msg} user={user} />
                        <div
                          className="message__msg-controls"
                          ref={controlsBtnsRef}
                        >
                          {user?._id === msg.userId._id ? (
                            <>
                              <div onClick={() => handleEditMsg(msg)}>
                                {t("chat.edit")}
                              </div>
                              <span className="chat__separator">•</span>
                              <div onClick={(e) => handleDeleteMsgClick(msg)}>
                                {t("chat.delete")}
                              </div>
                            </>
                          ) : (
                            <>
                              <div onClick={(e) => handleReplayMsg(msg)}>
                                {t("chat.replay")}
                              </div>
                              {user && user?.role.includes("Admin") && (
                                <>
                                  <span className="chat__separator">•</span>
                                  <div
                                    onClick={() => handleDeleteMsgClick(msg)}
                                  >
                                    {t("chat.delete")}
                                  </div>
                                </>
                              )}
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
                {!isChatFullScreent && messages.length > 3 && (
                  <div className="chat-showall-msg_wrapper">
                    <div
                      className="chat-showall-msg cancel-btn"
                      onClick={() => {
                        setIsChatFullScreent(true);
                      }}
                    >
                      {t("chat.showAll")}
                    </div>
                  </div>
                )}
              </div>
            )}
          </>
        )}
        {isNewMessages && (
          <div
            className="new_messages-notification primary-btn"
            onClick={() => handleNewMessagesNotificationClick()}
          >
            <img src="/update.svg" alt="update" />
            {newMessagesCount}
            {t("chat.newMessages")}
          </div>
        )}
      </div>

      <div className="chat__input-form_wrapper">
        {parentMessage && (
          <div className="chat__replayTo-wrapper">
            <div className="chat__replayTo-title">{t("chat.replayTo")}</div>
            <div className="chat__replayTo-user">
              {prepareFullName(parentMessage.userId)}
            </div>
            <div className="close-btn" onClick={() => handleCancelReplay()}>
              <span
                style={{
                  fontSize: "8px",
                  fontWeight: "bold",
                  marginRight: "8px",
                }}
              >
                ❌
              </span>
            </div>
          </div>
        )}
        <div
          className={`chat__input-form ${isTextareaActive ? "active" : ""}`}
          ref={chatInputFormRef}
        >
          <div className="chat__input-wrapper">
            <UserAvatar user={user} />
            <textarea
              ref={textareaRef}
              className="chat__input"
              value={message}
              maxLength={500}
              onChange={(e) => setMessage(e.target.value)}
              onFocus={handleFocus}
              onKeyDown={handleKeyDown}
              placeholder={t("chat.inputPlaceholder")}
              onInput={handleInput}
            />
          </div>

          {isEmojiOpen && (
            <div className="emoji-wrapper" ref={emojiRef}>
              <Picker
                emojiButtonSize={48}
                emojiSize={24}
                dynamicWidth={true}
                maxFrequentRows={2}
                locale={langStr}
                theme="light"
                data={data}
                previewPosition="none"
                onEmojiSelect={onEmojiClick}
                onClickOutside={() => setIsEmojiOpen(false)}
              />
            </div>
          )}

          {isTextareaActive && (
            <div
              className={
                isMessageLimit
                  ? "chat__message-limit limited"
                  : "chat__message-limit"
              }
            >
              {isMessageLimit && <span>{t("chat.msgLimit")}</span>}
              <span>{`${message.length}/${MESSAGE_LIMIT}`}</span>
            </div>
          )}
          <div className="chat__input-controls">
            {isTextareaActive && (
              <ToolTip text={t("chat.emoji")}>
                <div
                  ref={emojiButtonRef}
                  className={
                    isEmojiOpen ? "chat__emoji_btn active" : "chat__emoji_btn"
                  }
                  onClick={(e) => handleEmojiClick(e)}
                >
                  <img src="/Smiling.svg" alt="smiling" />
                </div>
              </ToolTip>
            )}
            <div className="chat__input-controls-btn">
              {isEdit && (
                <div
                  className="chat__cancel_btn cancel-btn"
                  onClick={() => handleCancelEdit()}
                >
                  {t("chat.cancel")}
                </div>
              )}
              <div
                className="chat__send_btn primary-btn"
                onClick={sendMessage}
                ref={sendButtonRef}
                style={{
                  opacity:
                    isLoading || (!isSentActive && isTextareaActive && user)
                      ? "60%"
                      : "",
                  pointerEvents:
                    isLoading || (!isSentActive && isTextareaActive && user)
                      ? "none"
                      : "",
                }}
              >
                {isTextareaActive && !user
                  ? t("chat.signInToSent")
                  : isEdit
                  ? t("chat.save")
                  : t("chat.sent")}
              </div>
            </div>
          </div>
        </div>
      </div>

      <Model
        isModel={showRemoveMsgModal}
        modelClassName={"remove-game-msg__modal"}
        hideModel={() => setShowRemoveMsgModal(false)}
        modelTitle={t("chat.removeMsg")}
      >
        <div className="chat-modal__wrapper">
          <div className="chat-modal__warning-text">
            {t("chat.removeMsgWarning")}
          </div>
          <div className="chat-modal__controls">
            <div className="primary-btn" onClick={() => handleDeleteMsg()}>
              {t("chat.delete")}
            </div>
            <div
              className="cancel-btn"
              onClick={() => setShowRemoveMsgModal(false)}
            >
              {t("chat.cancel")}
            </div>
          </div>
        </div>
      </Model>

      <LoginModal
        showLoginModel={showLoginModel}
        setShowLoginModel={setShowLoginModel}
      />

      {showMsgRemovedNotification && (
        <AlertNotification
          message={t("chat.messageRemoved")}
          onClose={() => setShowMsgRemovedNotification(false)}
          duration={2000}
          isVisible={showMsgRemovedNotification}
        />
      )}

      {showEmptyMsgNotification && (
        <AlertNotification
          message={t("chat.emptyMsg")}
          onClose={() => setShowEmptyMsgNotification(false)}
          duration={2000}
          icon="danger"
          isVisible={showEmptyMsgNotification}
        />
      )}
    </div>
  );
};

export default Chat;
