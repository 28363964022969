import { prepareFullName } from "../../../../helpers/formater";

export const getTitle = (item, t) => {
  if (!item._id) return;

  if (item.notificationKey === 5) {
    return t("notificationTitles.msgDeletedTitle");
  }

  if (item.notificationKey === 6) {
    return t("notificationTitles.msgReplayTitle");
  }

  return localStorage.getItem("language") === "en"
    ? item.title.en
    : item.title.ua;
};

export const getDescription = (item, t) => {
  if (!item._id) return;

  if (item.notificationKey === 5) {
    return t("notificationTitles.msgDeletedDescriptionWithMsg", {
      msg: item.body.message,
    });
  }

  if (item.notificationKey === 6) {
    if (item.body.data) {
      const data = JSON.parse(item.body.data);
      return t("notificationTitles.msgReplayDescriptionWithMsg", {
        username: prepareFullName(data.userId),
        msg: data.message,
      });
    }

    return "";
  }

  return localStorage.getItem("language") === "en"
    ? item.body.en
    : item.body.ua;
};
