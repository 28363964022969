import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import LoginModal from "../../../General/LoginModal/LoginModal";

import { formatNumber } from "../../../../helpers/formater";
import apiService from "../../../../services/apiService";

import "./GameVote.css";

const GameVote = ({ gameId, gameAPIDetails, gameDetails }) => {
  const { t, i18n } = useTranslation();

  const [gameVote, setGameVote] = useState({});
  const [userVote, setUserVote] = useState({});
  const [showLoginModel, setShowLoginModel] = useState(false);

  useEffect(() => {
    getGameVote();
    getUserVote();
  }, [gameAPIDetails]);

  const getOcRate = () => {
    if (gameAPIDetails?.game_oc_list) {
      const result = gameAPIDetails?.game_oc_list.find(
        (item) => item.group_id === 1
      );

      if (result) {
        return result.oc_list;
      }
    }

    return [{ oc_rate: "-" }, { oc_rate: "-" }, { oc_rate: "-" }];
  };

  const getGameVote = async () => {
    if (gameId) {
      const res = await apiService.getGameVote(gameId);
      if (res) {
        setGameVote(res);
      }
    }
  };

  const getUserVote = async () => {
    if (gameId) {
      const res = await apiService.getUserVote(gameId);

      if (res) {
        setUserVote(res[0]);
      }
    }
  };

  const addGameVote = async (ocName) => {
    if (!localStorage.getItem("token")) {
      setShowLoginModel(true);
      return;
    }

    const data = {
      gameId: gameId,
      ocName: ocName,
    };

    const res = await apiService.addGameVote(data);

    if (res) {
      setGameVote(res);
      setUserVote(res.userVote);
    }
  };

  const renderGameVoteRating = () => {
    return (
      <>
        {gameVote.voteData ? (
          <>
            <div className="game-vote_rating-items">
              <div
                className="game-vote_rating-item item-p1"
                // className={
                //   userVote?.ocName === "p1"
                //     ? "game-vote_rating-item active item-p1"
                //     : "game-vote_rating-item item-p1"
                // }
                style={{
                  width: gameVote?.voteData?.p1?.percentage
                    ? gameVote?.voteData?.p1?.percentage + "%"
                    : "",
                }}
              >
                <div className="game-vote_progress item-p1"></div>
              </div>
              <div
                className="game-vote_rating-item item-x"
                // className={
                //   userVote?.ocName === "x"
                //     ? "game-vote_rating-item active item-x"
                //     : "game-vote_rating-item item-x"
                // }
                style={{
                  width: gameVote?.voteData?.x?.percentage
                    ? gameVote?.voteData?.x?.percentage + "%"
                    : "",
                }}
              >
                <div className="game-vote_progress item-x"></div>
              </div>
              <div
                className="game-vote_rating-item item-p2"
                // className={
                //   userVote?.ocName === "p2"
                //     ? "game-vote_rating-item active item-p2"
                //     : "game-vote_rating-item item-p2"
                // }
                style={{
                  width: gameVote?.voteData?.p2?.percentage
                    ? gameVote?.voteData?.p2?.percentage + "%"
                    : "",
                }}
              >
                <div className="game-vote_progress item-p2"></div>
              </div>
            </div>
            <div className="game-vote_rating-titles">
              <div className="game-vote_progress-title">
                {gameVote?.voteData?.p1?.percentage
                  ? formatNumber(gameVote?.voteData?.p1?.percentage, 1)
                  : "0"}
                % <span>({t("gameDetail.votes.p1")})</span>
              </div>
              <div className="game-vote_progress-title">
                {gameVote?.voteData?.x?.percentage
                  ? formatNumber(gameVote?.voteData?.x?.percentage, 1)
                  : "0"}
                % <span>({t("gameDetail.votes.x")})</span>
              </div>
              <div className="game-vote_progress-title">
                {gameVote?.voteData?.p2?.percentage
                  ? formatNumber(gameVote?.voteData?.p2?.percentage, 1)
                  : "0"}
                % <span>({t("gameDetail.votes.p2")})</span>
              </div>
            </div>
          </>
        ) : (
          <div className="game-vote__no-votes">
            {t("gameDetail.votes.noVotes")}
          </div>
        )}
      </>
    );
  };

  const renderGameVoteItems = () => {
    return (
      <div className="game-vote_items">
        <div
          className="game-vote_item item-p1"
          onClick={() => addGameVote("p1")}
        >
          <div className="game-vote_item-title">{t("gameDetail.votes.p1")}</div>
          <div className="game-vote_item-value">
            {gameAPIDetails?.game_id ? (
              <>
                {getOcRate()[0]["oc_rate"] !== "-"
                  ? getOcRate()[0]["oc_rate"].toFixed(2)
                  : getOcRate()[0]["oc_rate"]}
              </>
            ) : (
              <div className="skeleton-placeholder-light game-vote_item-value_loader"></div>
            )}
          </div>
        </div>
        <div className="game-vote_item item-x" onClick={() => addGameVote("x")}>
          <div className="game-vote_item-title">{t("gameDetail.votes.x")}</div>
          <div className="game-vote_item-value">
            {gameAPIDetails?.game_id ? (
              <>
                {getOcRate()[1]["oc_rate"] !== "-"
                  ? getOcRate()[1]["oc_rate"].toFixed(2)
                  : getOcRate()[1]["oc_rate"]}
              </>
            ) : (
              <div className="skeleton-placeholder-light game-vote_item-value_loader"></div>
            )}
          </div>
        </div>
        <div
          className="game-vote_item item-p2"
          onClick={() => addGameVote("p2")}
        >
          <div className="game-vote_item-title ">
            {t("gameDetail.votes.p2")}
          </div>
          <div className="game-vote_item-value">
            {gameAPIDetails?.game_id ? (
              <>
                {getOcRate()[2]["oc_rate"] !== "-"
                  ? getOcRate()[2]["oc_rate"].toFixed(2)
                  : getOcRate()[2]["oc_rate"]}
              </>
            ) : (
              <div className="skeleton-placeholder-light game-vote_item-value_loader"></div>
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="game-vote__wrapper">
      <div className="game-vote__title-wrapper">
        <div className="game-vote__title">{t("gameDetail.votes.title")}</div>
        {userVote?.ocName && (
          <div className="game-vote__user-vote">
            {t("gameDetail.votes.youVote")}
            <span>
              {userVote?.ocName === "p1"
                ? t("gameDetail.votes.p1")
                : userVote?.ocName === "x"
                ? t("gameDetail.votes.x")
                : t("gameDetail.votes.p2")}
            </span>
          </div>
        )}
      </div>
      {userVote?.ocName || gameDetails.gameStatus === "completed"
        ? renderGameVoteRating()
        : renderGameVoteItems()}

      <LoginModal
        showLoginModel={showLoginModel}
        setShowLoginModel={setShowLoginModel}
      />
    </div>
  );
};

export default GameVote;
