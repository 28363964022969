import React from "react";

function UserAvatar({ user }) {
  return (
    <div className="user-avatar">
      {!user ? (
        <img src="/user-icon.svg" alt="avatar" />
      ) : user?.profileImgUrl ? (
        <img src={user.profileImgUrl} alt="avatar" />
      ) : user?.firstName || user?.lastName ? (
        <>{user?.firstName?.charAt(0) || user?.lastName?.charAt(0)}</>
      ) : (
        <img src="/user-icon.svg" alt="avatar" />
      )}
    </div>
  );
}

export default UserAvatar;
