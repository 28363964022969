import React, { useEffect, useState } from "react";
import { formatDateDistance } from "../../../../helpers/formater";

const MsgTimeDistance = ({ timestamp, lang }) => {
  const [formattedTime, setFormattedTime] = useState("");

  useEffect(() => {
    const updateFormattedTime = () => {
      setFormattedTime(formatDateDistance(timestamp, lang));
    };

    updateFormattedTime();

    const intervalId = setInterval(updateFormattedTime, 10000);

    return () => clearInterval(intervalId);
  }, [timestamp, lang]);

  return (
    <span className="message__msg-date">
      {formattedTime}
    </span>
  );
};

export default MsgTimeDistance;
