import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";

import { isValidEmail } from "../../../helpers/validator";
import { CONSTANT } from "../../../common/constant";
import { eventBus } from "../../../services/events";
import apiService from "../../../services/apiService";

import Model from "../../molecule/model/Model";

const LoginModal = ({showLoginModel, setShowLoginModel}) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const [loginEmail, setLoginEmail] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [userName, setUserName] = useState("");

  const [error, setError] = useState("form-error-line-hide");
  const [errorClass, setErrorClass] = useState("mb-3");

  const handleLoginEmail = (e) => {
    if (!isValidEmail(e.target.value)) {
      setUserName(e.target.value);
    } else {
      setUserEmail(e.target.value);
    }
    setLoginEmail(e.target.value);
    if (e.target.value === "") {
      setErrorClass("mb-3");
      setError("form-error-line-hide");
    }
  };

  const handleEmail = async (e) => {
    e.preventDefault();
    eventBus.emit(CONSTANT.SHOW_LOADER);
    const res = await apiService.getUserName({ email: loginEmail });
    eventBus.emit(CONSTANT.HIDE_LOADER);
    if (res.data) {
      navigate("/login", {
        state: {
          email: loginEmail,
          userName: userName,
          userEmail: userEmail,
          name: res.data.userName,
          profileImgUrl: res?.data.profileImgUrl,
        },
      });
    } else {
      setErrorClass("mb-3 form-error");
      setError("form-error-line-show");
    }
  };

  return (
    <>
      <Model
        isModel={showLoginModel}
        modelClassName={"home-page-signupPage"}
        hideModel={() => setShowLoginModel(false)}
        modelTitle={t("homePageSigninPage.SignIn")}
      >
        <div className="userLoginModel">
          <div className="top">
            <div className="btn-containt">
              <button
                type="button"
                className="btn  btn-google-facebook btn-google"
                onClick={() => {
                  window.location.href = process.env.REACT_APP_googleOAuthUrl;
                }}
              >
                <span className="signup-icon">
                  <i className="fab fa-google" />
                </span>
                {t("homePageSigninPage.isGoogleBtn")}
              </button>
              <button
                type="button"
                className="btn btn-google-facebook btn-facebook"
                onClick={() => {
                  window.location.href = process.env.REACT_APP_facebookOAuthUrl;
                }}
              >
                <span className="signup-icon">
                  <i
                    className="fab fa-facebook-f"
                    style={{ color: "#4267B2" }}
                  ></i>
                </span>
                {t("homePageSigninPage.isFabookBtn")}
              </button>
            </div>
            <div className="text-divider">{t("homePageSigninPage.or")}</div>
          </div>
          {/* ------ signincapperauditor form ------  */}
          <div className="bottom">
            <form>
              <div className={errorClass}>
                <label
                  htmlFor="exampleInputEmail1"
                  className="form-label form-input-label"
                >
                  {t("homePageSigninPage.emailorusername")}
                </label>
                <input
                  type="email"
                  className="form-control form-input-field"
                  id="InputEmail"
                  defaultValue={""}
                  onChange={handleLoginEmail}
                  placeholder={t("homePageSigninPage.EnteremailPlaceHolder")}
                  style={{
                    fontSize: "14px",
                    fontWeight: "300",
                    color: "#7F87B3",
                  }}
                />
                <p style={{ fontSize: "14px" }} className={error}>
                  {t("SignIn.Youremailorusernameisincorrect")}
                </p>
              </div>
              <div className="mb-4">
                <button
                  className="form-control form-input-field-button"
                  id="Inputbutton"
                  onClick={handleEmail}
                  disabled={loginEmail === ""}
                  style={{
                    pointerEvents: loginEmail === "" ? "none" : "auto",
                  }}
                >
                  {t("homePageSigninPage.Continue")}
                </button>
              </div>
            </form>
            {/* ------- signincapperauditor footer -----  */}
            <div className="signin-footer">
              <span
                className="signincapperauditor-bottom"
                style={{
                  fontSize: "14px",
                  fontWeight: "300",
                  lineHeight: "20px",
                }}
              >
                {t("homePageSigninPage.subLine1")}{" "}
                <Link
                  to="/signup"
                  style={{
                    color: "#4C5480",
                    fontWeight: "600",
                    lineHeight: "24px",
                  }}
                >
                  {t("homePageSigninPage.subLine2")}
                </Link>
              </span>
            </div>
          </div>
        </div>
      </Model>
    </>
  );
};

export default LoginModal;
