import "./css/App.css";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";

import Signup from "./components/AuthForms/Signup";
import Login from "./components/AuthForms/Login";
import SigninCapperauditor from "./components/AuthForms/SigninCapperauditor";
import Register from "./components/AuthForms/Register";
import OAuthRedirect from "./components/AuthForms/OAuthRedirect";
import SettingUpAccount from "./components/AuthForms/SettingUpAccount";

import ResetPassword from "./components/ResetPassword";
import ResetNewPassword from "./components/ResetNewPassword";
import CheckMail from "./components/CheckMail";
import UserProfile from "./components/UserProfile";
import ReturnLogin from "./components/ReturnLogin";
import LoginwithChangeUsername from "./components/LoginwithChangeUsername";
import Protected from "./components/Protected";
import PrivacyPolicy from "./components/PrivacyPolicy";
import Profile from "./components/publicProfile/Profile";
import Setting from "./components/setting/Setting";
import VerifyEmail from "./components/VerifyEmail";
import { AppProvider } from "@shopify/polaris";
import "@shopify/polaris/build/esm/styles.css";
import enTranslations from "@shopify/polaris/locales/en.json";
import Feed from "./components/Feed";
import ChartSummary from "./components/molecule/Chart/ChartSummary";
// import HomePage from "./components/molecule/homepage/HomePage";
import ErrorPage from "./components/molecule/ErrorPage/ErrorPage";
import CookiePolicy from "./components/molecule/CookiePolicy/CookiePolicy";
import Policy from "./components/molecule/PrivacyPolicy/Policy";
import HomePagePrediction from "./components/molecule/homepage/homePagePrediction/HomePagePrediction";
import Admin from "./components/Admin-panel/Admin";
import MobilePredictionListLoader from "./components/Custom loader/MobilePredictionListLoader";
import { useEffect, useLayoutEffect, useState } from "react";
import Notification from "./components/molecule/navbarNotification/Notification";
import Giveaways from "./components/molecule/Giveaways/Giveaways";
import TournamentDetailPage from "./components/molecule/Giveaways/TournamentDetailPage";
import SportLine from "./components/SportLine/SportLine";
import Wallet from "./components/Wallet";
import Marketplace from "./components/Marketplace/Marketplace";
import ExitConfirmationModal from "./components/molecule/model/ExitConfirmationModal";
// import HomePageTwo from "./components/molecule/homepage/HomePageTwo";
import HomePage from "./components/molecule/homepage/HomePage";
import Leaderboard from "./components/Leaderboard/Leaderboard";
import ResultsPage from "./components/ResultsPage/ResultsPage";
import { dynamicActivate } from "./i18n";
import RewardsPage from "./components/Rewards/RewardsPage";
import Model from "./components/molecule/model/Model";
import { t } from "i18next";
import apiService from "./services/apiService";
import GameDetail from "./components/GameDetail/GameDetail";
import { useDispatch } from "react-redux";
import { clearEvent, setLanguage } from "./store/betSlice";
import { Helmet } from "react-helmet";
import PredictionDetailsModalNotification from "./components/General/PredictionDetailsModalNotification/PredictionDetailsModalNotification";

const Wrapper = ({ children }) => {
  const location = useLocation();

  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);
  return children;
};

const App = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isMobile, setIsMobile] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [imageSrc, setImageSrc] = useState(null);

  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const handleCloseRewardModal = async () => {
    const res = await apiService.updateUserRewardStatus();
    if (res) {
      localStorage.setItem("profile", JSON.stringify(res));
    }
    setShowModal(false);
  };

  const handleOpenRewardsPage = async () => {
    setShowModal(false);
    const res = await apiService.updateUserRewardStatus();
    if (res) {
      localStorage.setItem("profile", JSON.stringify(res));
    }
    navigate("/achievements");
  };

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("profile"));
    if (user) {
      const userDate = new Date(user.createdDate * 1000);
      const date = new Date("2025-01-01");
      if (!user.pageDetailModalShowed && userDate < date) {
        setShowModal(true);
      }
    }

    const currentPath = location.pathname;

    if (currentPath.includes("/en")) {
      localStorage.setItem("language", "en");
      dynamicActivate("en");
      setImageSrc("https://capperauditor.com/preview-image_en.png");

      dispatch(setLanguage("en"));
      dispatch(clearEvent());
    } else {
      localStorage.setItem("language", "ua");
      dynamicActivate("ua");
      setImageSrc("https://capperauditor.com/preview-image.png");

      dispatch(setLanguage("ua"));
      dispatch(clearEvent());
    }

    const language = localStorage.getItem("language") || "en";
    document.documentElement.lang = language === "ua" ? "uk" : "en";
  }, []);

  const handleGuestuserModel = () => {
    if (!sessionStorage.getItem("isVisiedUser")) {
      if (
        location.pathname === "/" ||
        location.pathname === "/predictions" ||
        location.pathname === "/sportline"
      ) {
        setShowConfirmationModal(true);
        sessionStorage.setItem("isVisiedUser", false);
        // localStorage.setItem("isVisiedUser", false)
      }
    }
  };
  const handleCloseGuestuserModel = () => {
    setShowConfirmationModal(false);
    // localStorage.setItem("isVisiedUser", true)
    sessionStorage.setItem("isVisiedUser", true);
  };

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      const timeoutId = setTimeout(handleGuestuserModel, 15000);
      return () => clearTimeout(timeoutId);
    }
  }, [location.pathname]);

  useEffect(() => {
    const lang = localStorage.getItem("language");
    if (lang) {
      dynamicActivate(lang);
    } else {
      fetch("https://ipapi.co/json/")
        .then((response) => response.json())
        .then((data) => {
          if (data.country_name === "Ukraine") {
            localStorage.setItem("language", "ua");
            dynamicActivate("ua");
          } else {
            localStorage.setItem("language", "en");
            dynamicActivate("en");
          }
        })
        .catch((error) => {
          localStorage.setItem("language", "ua");
          dynamicActivate("ua");
          console.error("Error fetching country:", error);
        });
    }

    const screenWidth = window.innerWidth;
    const isMobileSize = screenWidth - 12 <= 768;
    if (isMobileSize) {
      setIsMobile(true);
    }
  }, []);

  const handlBodyClick = (e) => {
    if (isMobile) {
      if (
        e.target.id == "dropdown-menu" ||
        e.target.parentNode.id == "dropdown-menu"
      ) {
        document
          .getElementsByTagName("html")[0]
          .style.setProperty("overflow-y", "hidden", "important");
        document
          .getElementsByTagName("body")[0]
          .style.setProperty("overflow-y", "hidden", "important");
      } else {
        document
          .getElementsByTagName("html")[0]
          .style.setProperty("overflow-y", "unset");
        document
          .getElementsByTagName("body")[0]
          .style.setProperty("overflow-y", "unset");
      }
    }
  };

  return (
    <div className="App">
      <Helmet>
        <meta name="og:image" content={imageSrc} />
        <meta name="twitter:image" content={imageSrc} />
        <meta name="twitter:site" content="@Capperauditor" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="og:type" content="website" />
      </Helmet>

      <ExitConfirmationModal
        isModel={showConfirmationModal}
        hideModel={handleCloseGuestuserModel}
      />
      <AppProvider i18n={enTranslations}>
        <Wrapper>
          <Routes>
            {/* <Route
              path="/leaderboard"
              element={<Protected Component={Leaderboard} />}
            /> */}
            <Route
              path="/achievements"
              element={<Protected Component={RewardsPage} />}
            />
            <Route
              path="/en/achievements"
              element={<Protected Component={RewardsPage} />}
            />

            <Route
              path="/userprofile"
              element={<Protected Component={UserProfile} />}
            />
            <Route
              path="/en/userprofile"
              element={<Protected Component={UserProfile} />}
            />

            <Route
              path="/setting"
              element={<Protected Component={Setting} />}
            />
            <Route
              path="/en/setting"
              element={<Protected Component={Setting} />}
            />

            <Route
              path="/notification"
              element={<Protected Component={Notification} />}
            />
            <Route
              path="/en/notification"
              element={<Protected Component={Notification} />}
            />

            <Route path="/admin" element={<Protected Component={Admin} />} />
            <Route path="/en/admin" element={<Protected Component={Admin} />} />

            <Route path="/feed" element={<Protected Component={Feed} />} />
            <Route path="/en/feed" element={<Protected Component={Feed} />} />

            <Route path="/wallet" element={<Protected Component={Wallet} />} />
            <Route
              path="/en/wallet"
              element={<Protected Component={Wallet} />}
            />

            {/* auth route */}
            <Route exact path="/login" element={<Login />} />
            {/* <Route exact path="/Giveaways" element={<Giveaways />} /> */}

            <Route exact path="/signin" element={<SigninCapperauditor />} />
            <Route exact path="/signup" element={<Signup />} />
            {/* <Route path="/loader" element={<MobilePredictionListLoader />} /> */}

            <Route path="/profile/:getUsername" element={<Profile />} />
            <Route path="/en/profile/:getUsername" element={<Profile />} />

            <Route path="/predictions/:gameId" element={<GameDetail />} />
            <Route path="/en/predictions/:gameId" element={<GameDetail />} />

            <Route path="/leaderboard" element={<Leaderboard />} />
            <Route path="/en/leaderboard" element={<Leaderboard />} />

            <Route path="/marketplace" element={<Marketplace />} />
            <Route path="/en/marketplace" element={<Marketplace />} />

            <Route path="/sportline" element={<SportLine />} />
            <Route path="/en/sportline" element={<SportLine />} />

            <Route path="/predictions" element={<HomePagePrediction />} />
            <Route path="/en/predictions" element={<HomePagePrediction />} />

            <Route
              path="/prediction"
              element={<Navigate to="/predictions" replace />}
            />
            <Route
              path="/en/prediction"
              element={<Navigate to="/en/predictions" replace />}
            />

            <Route
              path="/tournamentDetail/:giveAwayid"
              element={<TournamentDetailPage />}
            />
            <Route path="/verifyemail" element={<VerifyEmail />} />
            <Route exact path="/resetpassword" element={<ResetPassword />} />
            <Route
              exact
              path="/resetnewpassword"
              element={<ResetNewPassword />}
            />
            <Route exact path="/register" element={<Register />} />
            <Route
              exact
              path="/settingaccount"
              element={<SettingUpAccount />}
            />
            <Route exact path="/checkmail" element={<CheckMail />} />
            <Route exact path="/returnlogin" element={<ReturnLogin />} />
            <Route exact path="/chart" element={<ChartSummary />} />
            {/* <Route exact path="/userprofile" element={<UserProfile />} /> */}
            <Route exact path="/oauth-redirect" element={<OAuthRedirect />} />

            {localStorage.getItem("token") &&
            localStorage.getItem("profile") ? (
              <Route exact path="/" element={<UserProfile />} />
            ) : (
              <Route exact path="/" element={<HomePage />} />
            )}

            {localStorage.getItem("token") &&
            localStorage.getItem("profile") ? (
              <Route exact path="/en" element={<UserProfile />} />
            ) : (
              <Route exact path="/en" element={<HomePage />} />
            )}

            <Route
              exact
              path="/loginchangeusername"
              element={<LoginwithChangeUsername />}
            />
            {/* <Route exact path="/privacy" element={<PrivacyPolicy />} /> */}
            <Route path="*" element={<ErrorPage />} />
            <Route path="/cookie" element={<CookiePolicy />} />
            <Route path="/en/cookie" element={<CookiePolicy />} />

            <Route path="/privacypolicy" element={<Policy />} />
            <Route path="/en/privacypolicy" element={<Policy />} />
          </Routes>
        </Wrapper>
      </AppProvider>

      {/* <Model
        isModel={showModal}
        modelClassName={"new-reward-modal"}
        hideModel={() => setShowModal(false)}
      >
        <div className="new-reward-modal-content">
          <div className="new-reward-modal-img-wrapper">
            <img
              className="new-rewards-modal-img"
              src="/rewards/treasure.svg"
              alt=""
            />
          </div>
          <div className="new-reward-modal-text">
            <h1>{t("rewards.modalTitle")}</h1>
            <p>{t("rewards.modalSubTitle")}</p>
          </div>
          <div className="new-reward-modal-text-control">
            <button
              className="new-reward-modal-close-btn"
              onClick={() => handleCloseRewardModal()}
            >
              {t("rewards.close")}
            </button>
            <button
              className="new-reward-modal-open-btn"
              onClick={() => handleOpenRewardsPage()}
            >
              {t("rewards.open")}
            </button>
          </div>
        </div>
      </Model> */}

      <PredictionDetailsModalNotification showModal={showModal} setShowModal={setShowModal}/>
    </div>
  );
};

export default App;
